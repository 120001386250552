/**
 * Creates an accordion functionality for a details element.
 * @param {string} detailsSelector - The details element to create the accordion for.
 * @param {string} contentSelector - The accordion object with `shrink`, `open`, and `expand` methods.
 */
export default function accordionAnimation(detailsSelector, contentSelector) {
  document.querySelectorAll(detailsSelector).forEach((el) => {
    const summary = el.querySelector('summary');
    const content = el.querySelector(contentSelector);

    let animation = null;
    let isClosing = false;
    let isExpanding = false;

    summary.addEventListener('click', (e) => {
      e.preventDefault();
      el.style.overflow = 'hidden';

      if(isClosing || !el.open) {
        openAccordion();
      } else if(isExpanding || el.open) {
        shrinkAccordion();
      }
    });

    /**
     * Shrinks the accordion.
     */
    function shrinkAccordion() {
      isClosing = true;
      const startHeight = `${el.offsetHeight}px`;
      const endHeight = `${summary.offsetHeight}px`;

      if(animation) {
        animation.cancel();
      }

      animation = el.animate({
        height: [startHeight, endHeight],
      }, {
        duration: 200,
        easing: 'ease-out',
      });

      animation.onfinish = () => onAnimationFinish(false);
      animation.oncancel = () => isClosing = false;
    }

    /**
     * Opens the accordion.
     */
    function openAccordion() {
      el.style.height = `${el.offsetHeight}px`;
      el.open = true;

      requestAnimationFrame(() => expandAccordion());
    }

    /**
     * Expands the accordion.
     */
    function expandAccordion() {
      isExpanding = true;
      const startHeight = `${el.offsetHeight}px`;
      const endHeight = `${summary.offsetHeight + content.offsetHeight}px`;

      if(animation) {
        animation.cancel();
      }

      animation = el.animate({
        height: [startHeight, endHeight],
      }, {
        duration: 200,
        easing: 'ease-out',
      });

      animation.onfinish = () => onAnimationFinish(true);
      animation.oncancel = () => isExpanding = false;
    }

    /**
     *
     * @param {boolean} open - Indicates whether the accordion is open or not.
     */
    function onAnimationFinish(open) {
      el.open = open;
      animation = null;
      isClosing = false;
      isExpanding = false;
      el.style.height = el.style.overflow = '';
    }
  });
}
