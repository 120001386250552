import { toggleOverlay } from './utils/overlay.mjs';
import { lockFocus, unlockFocus } from './utils/lock-focus.js';

/**
 * Export the menu toggle functionality.
 */
export default function init() {
  initMenuToggle();
}

/**
 * Initializes the menu toggle functionality.
 */
function initMenuToggle() {
  /**
   * Click event handler for the open menu button.
   * Toggles the overlay for the mobile menu container.
   */
  function handleMenuButtonClick() {
    toggleOverlay(menuContainer);
    toggleOverlay(overlay);
    toggleIsActive();

    const isMenuActive = mobileMenu.classList.contains('is-active');


    if(isMenuActive) {
      lockFocus(mobileMenu);
    } else {
      unlockFocus();
    }
  }

  const openMenuButton = document.querySelector('.open-menu-button');
  const menuContainer = document.querySelector('.menu');
  const overlay = document.querySelector('.overlay');
  const mobileMenu = document.querySelector('.top-row');

  openMenuButton?.addEventListener('click', handleMenuButtonClick);
}

/**
 * Show/hide desktop menu on scroll.
 */

let lastScrollTop = 0;
window.addEventListener(
  'scroll',
  function() {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollThreshold = 150;
    const menuContainer = document.querySelector('.menu-container');

    if(scrollTop > lastScrollTop && scrollTop > scrollThreshold) {
      menuContainer.classList.add('collapsed');
    } else {
      menuContainer.classList.remove('collapsed');
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  },
  false
);

/**
 * Toggles the 'is-active' class on the '.open-menu-button' element.
 */
function toggleIsActive() {
  const button = document.querySelector('.open-menu-button');
  const topRow = document.querySelector('.top-row');
  if(button) {
    button.classList.toggle('is-active');
    topRow.classList.toggle('is-active');
  }
}
