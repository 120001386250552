import success from '../../static/images/success.svg';
import error from '../../static/images/error.svg';

/**
 * Show a toast message with the given key and status.
 * @param {string} key - The key for the message to show.
 * @param {number} status - The status code.
 */
export async function showToast(key, status) {
  const toast = document.querySelector('.toast');
  const toastIcon = document.querySelector('.toast-icon');
  const toastMessage = document.querySelector('.toast-message');
  let message;
  const culture = getCulture();

  console.log('Updated Culture:', culture);

  try {
    const response = await fetch(`/api/translate?key=${encodeURIComponent(key)}&culture=${encodeURIComponent(culture)}`);
    message = await response.text();
  } catch(error) {
    console.error('Error fetching translation', error);
    return;
  }

  if(status === 200) {
    toast.style.backgroundColor = '#9ae6b4';
    toastIcon.src = success;
  } else {
    toast.style.backgroundColor = '#feb2b2';
    toastIcon.src = error;
  }

  toastMessage.innerHTML = message;
  toast.classList.add('show');

  setTimeout(function() {
    toast.classList.remove('show');
  }, 3500);
}

/**
 * Get the current culture from the window location.
 * @returns {string} The current culture.
 */
function getCulture() {
  const pathname = window.location.pathname;
  let culture;

  if(pathname === '/') {
    culture = 'sv';
  } else {
    culture = pathname.split('/')[1];
  }

  if(culture === 'sv') {
    culture = 'sv-SE';
  } else if(culture === 'en') {
    culture = 'en-US';
  } else if(culture === 'de') {
    culture = 'de-DE';
  }

  return culture;
}
