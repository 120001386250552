let focusStack = [];

/**
 * @param { HTMLElement } element Element to lock focus inside.
 */
export function lockFocus(element) {
  if(focusStack.length > 0) {
    const { handler } = focusStack[focusStack.length - 1];
    document.removeEventListener('focus', handler, true);
  }

  const handler = createFocusHandler(element);
  focusStack.push({ element, handler });

  if(element.getAttribute('tabindex') === null) {
    element.setAttribute('tabindex', '-1');
  }

  document.addEventListener('focus', handler, true);
  toggleIframeTabindex(true);
}

/**
 * Unlock focus from the current element and re-lock it to the previous one if any.
 */
export function unlockFocus() {
  if(focusStack.length === 0) {
    return;
  }

  const { handler: unlockedHandler } = focusStack.pop();
  document.removeEventListener('focus', unlockedHandler, true);

  if(focusStack.length > 0) {
    const { element: topElement, handler: topHandler } = focusStack[focusStack.length - 1];
    document.addEventListener('focus', topHandler, true);
    topElement.focus();
  }

  if(focusStack.length === 0) {
    toggleIframeTabindex(false);
  }
}

/**
 * @param { HTMLElement } element Element to lock focus inside.
 * @returns { (event: Event) => void } Focus handler.
 */
function createFocusHandler(element) {
  return function focusHandler(event) {
    if(!element.contains(event.target)) {
      element.focus();
    }
  };
}

/**
 * This is bugfix that I added becuase the iframe is not a part of the tab order by default which
 * enables tabbig to it despite the focus being locked on the menu.
 * Toggles the tabindex attribute of an iframe in the document.
 * When `disable` is true, sets the tabindex to '-1' to remove the iframe from the tab order.
 * When `disable` is false, removes the tabindex attribute to restore the iframe to the tab order.
 * @param {boolean} disable - A flag indicating whether to disable or enable the iframe's tabindex.
 */
function toggleIframeTabindex(disable) {
  const iFrame = document.querySelector('iframe');
  if(iFrame) {
    if(disable) {
      iFrame.setAttribute('tabindex', '-1');
    } else {
      iFrame.removeAttribute('tabindex');
    }
  }
}
