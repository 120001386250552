/**
 * Toggles overlay for the given element.
 * @param {HTMLElement | Element | null | undefined} element - The target element for which the overlay will be toggled.
 */
export function toggleOverlay(element) {
  if(element === null || element === undefined) {
    return;
  }

  element.classList.toggle('show-overlay');
  toggleNoScroll();
}

/**
 * Toggles the 'no-scroll' class on the body based on the presence of any overlay.
 */
function toggleNoScroll() {
  const anyOverlayOpen = document.querySelectorAll('.show-overlay').length > 0;

  if(anyOverlayOpen) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
}
