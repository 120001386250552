import {showToast} from './utils/toast.mjs';

/**
 * Export the form submit functionality
 */
export default function init() {
  initForm();
}

/**
 * Initializes the form submit functionality.
 */
function initForm() {
  const forms = document.querySelectorAll('.form-block-container');

  forms.forEach((form) => {
    form.addEventListener('submit', async function(event) {
      event.preventDefault();
      const loader = form.querySelector('.loader');
      const submitButton = form.querySelector('.submit-btn');
      const inputFields = form.querySelectorAll('input');
      const selectBtn = form.querySelector('select');
      const textarea = form.querySelector('textarea');

      // Disable the form and show the loader
      submitButton.disabled = true;
      inputFields.forEach(inputField => inputField.disabled = true);
      selectBtn.disabled = true;
      textarea.disabled = true;
      loader.style.display = 'block';

      let firstname = form.querySelector('.firstname').value;
      let lastname = form.querySelector('.lastname').value;
      let email = form.querySelector('.email').value;
      let phone = form.querySelector('.phone').value;
      let select = form.querySelector('.select').value;
      let message = form.querySelector('.message').value;

      let verificationToken = form.querySelector(
        'input[name="__RequestVerificationToken"]'
      ).value;

      let formData = {
        FirstName: firstname,
        LastName: lastname,
        Email: email,
        Phone: phone,
        Select: select,
        Message: message,
      };

      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          RequestVerificationToken: verificationToken,
        },
        body: JSON.stringify(formData),
      };

      try {
        const response = await fetch('/form/submitform', options);

        if(response.status === 200) {
          showToast('Thanks for your message', response.status);
        } else {
          showToast('Something went wrong', response.status);
        }
      } catch(error) {
        console.error('There was an error with the fetch operation: ', error);
      } finally {
        // Enable the form and hide the loader
        submitButton.disabled = false;
        inputFields.forEach(inputField => inputField.disabled = false);
        selectBtn.disabled = false;
        textarea.disabled = false;
        loader.style.display = 'none';
      }

      form.querySelector('.firstname').value = '';
      form.querySelector('.lastname').value = '';
      form.querySelector('.email').value = '';
      form.querySelector('.phone').value = '';
      let selectElement = form.querySelector('.select');
      selectElement.selectedIndex = 0;
      form.querySelector('.message').value = '';
    });
  });
}
