import {showToast} from './utils/toast.mjs';

/**
 * Export the newsletter submit functionality
 */
export default function init() {
  initNewsletter();
}

/**
 * Initializes the newsletter submit functionality.
 */
function initNewsletter() {
  const newsletterSignUp = document.getElementById('newsletter-signup');

  newsletterSignUp.addEventListener('submit', async function(event) {
    event.preventDefault();

    const newsletterButton = document.getElementById('newsletter-submit');
    const input = document.getElementById('email');

    console.log('submitting newsletter', input.value);
    input.disabled = true;
    newsletterButton.disabled = true;

    let email = input.value;

    let verificationToken = newsletterSignUp.querySelector(
      'input[name="__RequestVerificationToken"]'
    ).value;

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        RequestVerificationToken: verificationToken,
      },
      body: JSON.stringify(email),
    };

    try {
      const response = await fetch('/newsletter/subscribe', options);

      const data = await response.json();

      console.log(data);

      if(data.statusCode === 200) {
        showToast('Subscribed', data.statusCode);
      } else if(data.statusCode === 400) {
        showToast('Already subscribed', data.statusCode);
      } else {
        showToast('Something went wrong', data.statusCode);
      }
    } catch(error) {
      console.error('Error submitting newsletter', error);
      showToast('Error occurred while submitting the newsletter.', 500);
    } finally {
      input.disabled = false;
      newsletterButton.disabled = false;
      input.value = '';
    }
  });
}
