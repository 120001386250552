/**
 * send event to dataLayer / GTM
 */

/**
 * @param { object } event Event to push to dataLayer.
 */
export function pushToDataLayer(event) {
  if(window.dataLayer) {
    window.dataLayer.push(event);
  }
}
