import {pushToDataLayer} from './tag-management';

/**
 * Initializes the tags.
 */
export default function initTags() {
  initCtaListeners();
}

/**
 * Initializes the CTA button listeners.
 */
function initCtaListeners() {
  document.addEventListener('DOMContentLoaded', function() {
    const ctaButtons = document.querySelectorAll('.cta-block');
    ctaButtons.forEach(function(ctaButton) {
      ctaButton.addEventListener('click', function(event) {
        const linkUrl = event.target.getAttribute('href').toLowerCase();
        const currentPageUrl = window.location.href;

        const bookingUrl = [
          'https://booking.swedenzipline.com'
        ];

        if(linkUrl.includes(bookingUrl)) {
          handleBookingClick(linkUrl, currentPageUrl);
        }
      });
    });
  });
}

/**
 * Handles the click on the booking button.
 * @param {string} linkUrl - The URL of the clicked link.
 * @param {string} currentPageUrl - The URL of the current page.
 */
function handleBookingClick(linkUrl, currentPageUrl) {
  let bookingType = 'type not specified';
  if(linkUrl.includes('boka/boende')) {
    bookingType = 'camping';
  } else if(linkUrl.includes('boka')) {
    bookingType = 'zipline';
  }

  const event = {
    event: 'click-book-now',
    name: 'user_interaction.cta_click.book_now',
    click_url: linkUrl,
    page_url: currentPageUrl,
    params: {
      product_category: bookingType,
    }
  };
  pushToDataLayer(event);
}
