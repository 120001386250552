import '../styles/fonts.scss';
import '../styles/index.scss';
import accordionAnimation from './utils/accordion.mjs';
import initMainMenu from './main-menu';
import initForm from './form';
import initAnimations from './setup-animations.mjs';
import './konamiCode';
import initNewsletter from './newsletter';
import initSlideshow from './slideshow.js';
import initTags from './tag-management/cta-tag-management.js';


initMainMenu();
accordionAnimation('.faq-item details', '.faq-answer');
initForm();
initAnimations();
initNewsletter();
initSlideshow();
initTags();
