/**
 * Animates an element with a specified animation class and optional delay.
 * @param {HTMLElement} element - The DOM element to animate.
 * @param {string} animation - The animation class to add to the element.
 * @param {number} [delay] - Optional delay (in milliseconds) before the animation starts.
 */
function animate(element, animation, delay = 0) {
  if(element === null) {
    return;
  }

  if(delay === 0) {
    element.classList.add(animation);
    return;
  }

  element.style.visibility = 'hidden';
  setTimeout(() => {
    element.style.visibility = 'visible';
    element.classList.add(animation);
  }, delay);
}

/**
 * Sets up animations for elements that should animate when they enter the screen.
 * @param {string[]} selectors - An array of CSS selectors for elements to animate on screen entry.
 */
function setupEnterScreenAnimations(selectors) {
  const elementsToFadeUpOnEnterScreen = document.querySelectorAll(selectors.join(', '));

  elementsToFadeUpOnEnterScreen.forEach((element) => {
    element.style.visibility = 'hidden';
  });

  triggerOnEnterScreen((element) => {
    element.style.visibility = 'visible';
    animate(element, 'fade-up');
  }, elementsToFadeUpOnEnterScreen);
}

/**
 * Observes elements and triggers a callback when they enter the screen.
 * @param {Function} enterScreenCallback - The callback function to execute when an element enters the screen.
 * @param {NodeList} elements - A NodeList of DOM elements to observe.
 */
function triggerOnEnterScreen(enterScreenCallback, elements) {
  const enterScreenObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        const element = entry.target;
        enterScreenCallback(element);
        enterScreenObserver.unobserve(element);
      }
    });
  });

  elements.forEach((element) => {
    enterScreenObserver.observe(element);
  });
}

export {
  animate,
  setupEnterScreenAnimations,
  triggerOnEnterScreen
};
